import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { minimalRoundedCorners } from '../RoundedCorners';
import gradients from '../Theme/gradients';

const Pipe = styled.span`
  padding: 0 4px;
  position: relative;
  bottom: 1px;
`;

const FeaturedLabel = styled.span`
  ${minimalRoundedCorners};
  color: var(--white);
  background-color: ${
  (properties) => properties.theme.palette?.primaryFallback.main
};
  background-color: ${
  (properties) => properties.theme.palette?.primary.main
};
  background-image: linear-gradient(to right, ${gradients.subtle});
  display: inline-block;
  margin: 0 0 0 16px;
  padding: 1px 2px 0px 3px;
  font-size: 0.5rem;
  text-align: center;
  position: relative;
  top: -1px;
`;

const Labels = ({ labels, featured }) => (
  <>
    { labels && labels.length > 0
    && (

      <>
        {' '}
        <Pipe>|</Pipe>
        {labels.map((label) => (
          <Fragment key={label}>
            {label}
            {' '}
          </Fragment>
        ))}
      </>
    )}
    {featured && (
      <FeaturedLabel>Featured</FeaturedLabel>
    )}
  </>
);

Labels.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  featured: PropTypes.bool,
};

Labels.defaultProps = {
  labels: [],
  featured: false,
};

export default Labels;
